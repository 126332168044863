/* Keyframe animation for pulsing bottom glow */
@keyframes pulseGlow {
  0%, 100% {
    opacity: 0.7;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
}

/* Keyframe animation for fade-in from below */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.feature_card {
  width: 224.65px;
  height: 290px;
  border-radius: 25px;
  overflow: hidden;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeInUp 0.6s ease forwards;
  perspective: 1000px; /* Enable 3D perspective */
}

/* Interactive scaling and tilt effect */
.feature_card:hover {
  transform: scale(1.03) rotateX(3deg); /* Slight tilt for 3D feel */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

/* Glow effect at bottom */
.feature_card::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 15px;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(0, 150, 255, 0.3), rgba(255, 0, 150, 0.3));
  opacity: 0.8;
  animation: pulseGlow 2s infinite ease-in-out;
  filter: blur(8px);
}

/* Responsive scaling */
@media (max-width: 768px) {
  .feature_card {
    width: 180px;
    height: 230px;
  }
  .feature_card-bottom h2 {
    font-size: 1rem;
  }
  .feature_card-bottom p {
    font-size: 0.8rem;
  }
}

/* Top section */
.feature_card-top {
  height: 35%;
  position: relative;
  z-index: 2;
}

.image-container {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -10%);
}

.feature_card-top img {
  margin-top: 20px;
  width: 130%;
}

/* Bottom section */
.feature_card-bottom {
  height: 74%;
  padding: 50px 20px 30px;
  text-align: center;
  border-radius: 25px 25px 0 0;
  position: relative;
  z-index: 1;
  color: #333;
  width: 98%;
  margin: auto;
  margin-left: 2px;
  margin-bottom: 2px;
  border-radius: 20px;
  background-color: var(--bottom-color);
}

.feature_card-bottom h2 {
  color: rgba(26, 25, 25, 0.822);
  font-size: 1.2rem;
  margin-bottom: 10px;
  margin-top: 25px;
}

.feature_card-bottom p {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.4;
}
