.header {
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 80px;
    padding: 0 20px;
    box-sizing: border-box;
}

.header__logo {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.header__logo-img {
    width: 60px;
    height: 60px;
}

.header__title {
    font-size: 30px;
    color: #5e17eb;
    margin-left: 9px;
    font-family: "Noto Sans", sans-serif;
    letter-spacing: 1px;
}

.header__search {
    display: flex;
    align-items: center;
    margin-left: 145px;
}

.header__search-input {
    width: 335px;
    height: 40px;
    padding: 0 14px;
    font-size: 16.1px;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 5px 0 0 5px;
    outline: none;
}

.header__search-icon {
    height: 40px;
    width: 40px;
    padding: 7px;
    border: 1px solid #ccc;
    border-left: none;
    background-color: white;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    size: 24px;
}

.header__divider2 {
    width: 2px;
    height: 43px;
    background-color: #f7f2f2;
    margin: 0 15px;
}

.header-right {
    display: flex;
    align-items: center;
}

.header__explore-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 40px;
    background-color: #6200ea;
    color: white;
    border: none;
    letter-spacing: 0.5px;
    border-radius: 400px;
    margin-left: 20px;
    font-size: 15px;
    cursor: pointer;
    position: relative; /* To keep shadow in relation to button */
    box-shadow: 0px 5px 10px rgba(40, 40, 177, 0.36); /* Light shadow below */
}

.header__notification-btn {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 20px;
}

.header__notification-icon {
    font-size: 24px;
    color: #6200ea;
}

.header__explore-icon {
    width: 26px;
    height: 26px;
    margin-right: 8px;
}

.header__auth-buttons {
    display: flex;
    align-items: center;
    margin-left: 14px;
}

.header__login-btn,
.header__register-btn {
    height: 40px;
    font-size: 16px;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
}

.header__login-btn {
    width: 105px;
    background-color: white;
    color: black;
    transition: background-color 0.3s ease;
    transition: all 0.3s ease;
    margin-right: 15px;
}

.header__login-btn:hover {
    background-color: white;
    color: black;
    transform: scale(1.0.5); /* Zoom effect */
}

.header__register-btn {
    width: 115px;
    background-color: black;
    color: white;
}

.header__register-btn:hover {
    background-color: white;
    color: black;
    transform: scale(1.0.5); /* Zoom effect */
}


.header__divider {
    width: 0.5px;
    height: 43px;
    background-color: #999;
    margin: 15 15px;
    opacity: 0.8;
}

.header__user-icon {
    font-size: 19px;
    margin-right: 9px;
}
