.footer {
    /* background-color: #e6f3ff; */
    /* background-color: #B9E7FF; */
    /* background-color: #0000ff;  */
    background-color: #B3ECFF !important;
    padding: 20px;
    font-family: Arial, sans-serif;
    clip-path: ellipse(100% 100% at 50% 100%);
}

.footer a {
    text-decoration: none;
    color: #333;
}

.bottom-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    margin-bottom: 40px;
}
.top-links{
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 40px;
}
.middle-links{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 40px;
}

.icon {
    margin-right: 5px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.social-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;  /* Makes the icon circular */
    transition: transform 0.3s;
}

.social-icons a:hover .social-icon {
    transform: scale(1.1); /* Zooms in the icon on hover */
}


.app-stores {
    margin:auto;
    display: flex;
    justify-content: center;
    gap: 0px;
    height:120px;
  }
  
  .app-stores a {
    display: flex;
    align-items: center;  /* Align icon and text vertically */
    gap: 10px;  /* Space between icon and text */
    color: #333;
    font-size: 1rem;
    text-decoration: none;
  }
  
  .app-stores a:hover {
    color: #000;  /* Optional hover effect */
  }
  

.bottom-links {
    font-size: 0.8em;
    color: #666;
}

.f {
    text-align: center;
    font-size: 0.8em;
    color: #666;
    margin-top: 30px;
    margin-bottom: 40px;

}
.custom-divider5 {
    width: 1px;
    height: 35px;
    background-color: #1275a0;  /* Ensure this color is consistent */
    margin: 0 15px;
    opacity: 0.3;  /* Set a consistent opacity */
}