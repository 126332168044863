.page_wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f7fb;
}

.course_container {
  display: flex;
  flex: 1;
  gap: 2rem;
  padding: 2rem;
  position: relative;
}

/* Left panel styles */
.course_left_panel {
  position: sticky;
  top: 2rem;
  height: calc(100vh - 119px);
  flex: 0 0 300px;
  padding: 1.5rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
}

.course_heading {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a237e;
  margin-bottom: 1.5rem;
  padding: 0.5rem 0;
  border-bottom: 2px solid #e8eaf6;
}

/* Section styles */
.course_section {
  margin-bottom: 0.75rem;
}

.course_section_header {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: #f8fafc;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  font-weight: 600;
  color: #3949ab;
}

.course_section_header:hover {
  background: #e8eaf6;
  transform: scale(1.02);
}

.course_section_header.course_active {
  background: #3949ab;
  color: white;
}

.section_icon {
  margin-right: 12px;
  font-size: 1.2rem;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.section_icon.rotated {
  transform: rotate(90deg);
}

.course_topics {
  margin-top: 0.5rem;
  padding-left: 2.5rem;
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.course_topic {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin: 4px 0;
  border-radius: 8px;
  font-size: 0.95rem;
  color: #5c6bc0;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.course_topic:hover {
  background: #e8eaf6;
  transform: translateX(8px);
}

.topic_active {
  background: #e8eaf6;
  color: #3949ab;
  font-weight: 600;
}

.topic_dot {
  width: 6px;
  height: 6px;
  background: currentColor;
  border-radius: 50%;
  margin-right: 12px;
}

/* Right panel styles */
.course_right_panel {
  flex: 1;
  overflow-y: auto;
}

.course_content_container {
  background: #007bff;
  border-radius: 16px;
  padding: 1.5rem;
  position: relative;
  height: 440px;
  color: white;
  margin-bottom: 2rem;
}

.course_content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
}

.course_logo {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1.5rem;
  font-weight: 700;
}

.python_logo {
  font-size: 2rem;
}

.course_content_body {
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 4rem;
  min-height: 200px;
}

.topic_content_list {
  list-style: none;
  padding: 0;
}

.topic_content_list li {
  padding: 2px;
  margin: 2px 0;
  border-radius: 8px;
}

.topic_content_list li:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateX(8px);
}

.start_lesson_btn {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  padding: 12px 32px;
  background: white;
  color: #4051b5;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.start_lesson_btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.course_action_buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 0 0.5rem;
}

.action_btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  border: none;
  border-radius: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  color: white;
}

.action_btn svg {
  font-size: 1.2rem;
}

.video_btn { background: #2196f3; }
.quiz_btn { background: #9c27b0; }
.plan_btn { background: #4caf50; }
.notice_btn { background: #f44336; }
.share_btn { background: #ff9800; }

.action_btn:hover {
  transform: translateY(-2px);
  filter: brightness(110%);
}

.course_description {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.course_description h3 {
  color: #1a237e;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.course_description p {
  color: #64748b;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.course_description ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
  color: #64748b;
}

.course_description li {
  margin-bottom: 0.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .course_container {
    flex-direction: column;
    padding: 1rem;
  }
  
  .course_left_panel {
    position: relative;
    height: auto;
    width: 100%;
    flex: none;
  }
  
  .course_action_buttons {
    flex-wrap: wrap;
  }
  
  .action_btn {
    flex: 1 1 calc(50% - 0.5rem);
  }
}