/* Header styles */
* {
    box-sizing: border-box;
}

.main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 2rem;
    padding-top: 20px;
    background-image: url("../../assets/images/background.svg");
    background-size:cover;
    background-position: center;
    /* background-repeat: no-repeat; */
    height: 363px; /* Adjust this based on your needs */
    width: 100%;

    /* Clip only the bottom of the container with ellipse shape */
    clip-path: ellipse(100% 100% at 50% 0%);
}


.right-image {
    width: 23%;
    margin-right: 75px;
    position: relative;
    transform: translateY(15%); 

}

.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 2rem;
    background-color: #d6e5f3;
}

.hero-content {
    max-width: 50%;
}

.hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.hero-buttons {
    display: flex;
    gap: 1rem;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.inline-you {
    display: inline-block;
    position: relative;
}

.you-image {
    display: block;
    width: 90px; /* Set width of the image */
    position: relative;
    top: -14px; /* Adjust this value to fine-tune image placement */
    margin-left: 5px; /* Adjust spacing between the text and image */
    transform: translateX(235px); /* Move image slightly downward */

}


.main-section {
    /* text-align: center; */
     width: 100%;
    position: relative;  
}

.main-text h2 {
    font-family: "Noto Sans",  sans-serif;
    font-size: 51px;
    color: #2e0c6e;
    text-align: left;
    margin-bottom: 20px;
    position: relative;
    transform: translateY(-40%);
    margin-left: 10px;
}

.main-text p {
    font-family: "Noto Sans",  sans-serif;
    position: relative;
    transform: translateY(-90%); 
    /* transform: translateX(3%);  */
    color: #2e0c6e;
    color:black;
    font-size: 18px;
    margin-bottom: 40px;
    margin-left: 14px;
}

.feature-section {
    display: flex;
    justify-content: center;
    gap:41px;
    flex-wrap: wrap;
    position: relative;
    transform: translateY(-50%); /* Move 30% above */
}

.feature-box {
    background-color: white; /* Light blue shade (AliceBlue) */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 255, 0.1); /* Slight blue glow */
    padding: 20px;
    padding-top: 0px;
    width: 360px;
    height: 183px;
    text-align: center;
    /* border: 1px solid #d0e0ff; Optional border with a blue shade */
}


.feature-box h5 {
    font-size: 24px;
    color: black;
    margin-bottom: 10px;
}

.feature-box p {
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
}

.feature-btn {
    border: 1px solid #007bff;
    color:  #007bff;
    padding: 10px 20px;
    border-radius: 15px;
}

.feature-btn:hover {
    background-color: #007bff;
    color:white;
}

.features {
    padding: 0.rem 2rem;
    background-color: #fff;
}


.features h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.feature-cards {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.card {
    flex: 1;
    padding: 2rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    text-align: center;
}

.card img {
    width: 64px;
    height: 64px;
    margin-bottom: 1rem;
}

.card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

/* Footer styles */
.footer {
    margin-top: auto;
    padding: 2rem;
    background-color: #f8f9fa;
    text-align: center;
}

.footer nav {
    margin-bottom: 1rem;
}

.footer a {
    color: #333;
    text-decoration: none;
    margin: 0 1rem;
}

.card-container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

/* Responsive design */
@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-right {
        margin-top: 1rem;
        width: 100%;
    }

    .hero {
        flex-direction: column;
        text-align: center;
    }

    .hero-content {
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .hero-buttons {
        justify-content: center;
    }

    .feature-cards {
        flex-direction: column;
    }

    .feature-section {
        transform: translateY(0); /* No translation on mobile to prevent overlap */
    }
}

.feature-btn {
    background-color: white;
    border: 1px solid #007bff;
    color:  #007bff;
    padding: 10px 20px;
    border-radius: 18px;
    cursor: pointer;
    font-size: 1rem;
}

.feature-btn:hover {
    background-color: #007bff;
    color:white;
}

.features {
    padding: 0.rem 2rem;
    background-color: #fff;
}