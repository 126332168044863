/* body{
  background:#38b2ac;
} */
.slides-container {
  min-height: 91vh;
  background: #c7e0f7;
  display: flex;
  flex-direction: column;
  outline: none;
  position: relative;
  width: 97%;
  margin: auto;
  /* margin-top: 50px; */
  padding: 50px 75px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.progress-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: #38b2ac;
  transition: width 0.3s ease;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #38b2ac;
  border: none;
  cursor: pointer;
  z-index: 100;
  color: white;
  padding: 4px;
  border-radius: 50%;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  transform: scale(1.14);
}

.slide-icon {
  position: relative;
  top:15px;
  color: #38b2ac;
  margin-bottom: 1.5rem;
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.first-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 2rem;
  position: relative;
}

.main-title {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 0.5rem;
}

.main-description {
  font-size: 1.2rem;
  color: #4a5568;
  max-width: 800px;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.main-image {
  max-width: 400px;
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.next-hint {
  position: absolute;
  bottom: 40px;
  right: 40px;
  color: #38b2ac;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  border-radius: 999px;
  background: rgba(56, 178, 172, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.next-hint:hover {
  background: rgba(56, 178, 172, 0.2);
  transform: translateX(5px);
}

.slide-navigation {
  position: relative;
  right:10px;
  bottom: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  pointer-events: none;
  width: 100%;
  z-index: 10;
}

.nav-arrow {
  position: relative;
  right:-550px;
  bottom: -60px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #c7e0f7;
  color: #22948f;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.3rem;
  transition: background-color 0.3s ease;
  pointer-events: auto;
  position: relative;
}

/* .nav-arrow:hover:not(:disabled) {
  background: rgba(0, 0, 0, 0.8);
} */

.nav-arrow:disabled {
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

/* Tooltip Styles */
.nav-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  white-space: nowrap;
  pointer-events: none;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-shortcut {
  font-size: 0.8rem;
  opacity: 0.8;
  margin-top: 0.2rem;
  text-align: center;
}

.slide-count {
  font-size: 16px;
  font-weight: 500;
  color: #4a5568;
  min-width: 60px;
  text-align: center;
}

.slide-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 6.5rem;
  width: 100%;
  padding: 1.5rem;
  /* background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); */
  margin-bottom: 30px;
}
.header-content-title{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:6px;
  width:320px;
  padding: 0;
}

.slide-title {
  font-family: Quicksand, "M PLUS Rounded 1c", "Hiragino Maru Gothic Pro", "Meiryo UI", Meiryo, "MS PGothic", sans-serif;
  font-size: 1.5rem;
  color: #2d3748;
  margin: 0;
}

.slide-description {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  color: #3f4348;
  margin: 0;
  line-height: 1.6;
}

.slide-main {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.editor-section, .output-section {
  flex: 1;
  background: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.editor-header, .output-header {
  background: #2d2d2d;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #3d3d3d;
}

.editor-controls {
  display: flex;
  gap: 0.5rem;
  margin-right: 1rem;
}

.control {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.control.red { background: #ff5f56; }
.control.yellow { background: #ffbd2e; }
.control.green { background: #27c93f; }

.file-name, .output-title {
  color: #999;
  font-size: 0.9rem;
  font-family: 'Fira Code', monospace;
}

.editor-content, .output-content {
  padding: 1.5rem;
}

.editor-content pre, .output-content pre {
  margin: 0;
  font-family: 'Fira Code', monospace;
  font-size: 1rem;
  line-height: 1.5;
}

.editor-content code {
  color: #d4d4d4;
}

.output-content code {
  color: #fff;
}

.output-explanation {
  margin-top: 1.5rem;
  padding: 1rem;
  background: rgba(56, 178, 172, 0.1);
  border-radius: 8px;
  position: relative;
}

.explanation-arrow {
  position: absolute;
  top: -1rem;
  left: 1rem;
  color: #2e6cf1;
  font-size: 1.5rem;
}

.output-explanation p {
  color: #2e6cf1;
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.6;
}

.last-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 2rem;
}

.last-slide h2 {
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 1rem;
}

.last-slide p {
  font-size: 1.2rem;
  color: #4a5568;
  max-width: 600px;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.start-exercise-btn {
  background: #38b2ac;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.start-exercise-btn:hover {
  background: #319795;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(56, 178, 172, 0.2);
}