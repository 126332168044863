.about_banner {
    display: flex;
    align-items: center;
    background-color: #b3ecff; /* Light blue background */
    padding: 40px;
    gap:0px;
    border-radius: 10px;
    height: 433px;
    clip-path: ellipse(100% 100% at 50% 0%);
  }
  
  .about_banner-text {
    flex: 1;
    padding-right: 20px;
    padding-top: 0px;
    transform: translateY(-15%); 
  }
  
  .about_banner-text h1 {
    text-align: center;
    font-size: 52px;
    color: #2e0c6e;
    margin: 0;
    padding: 0;
  }
  
  .about_banner-text p {
    font-size: 22px;
    color: #666;
    margin-top: 10px;
  }
  
  .about_banner-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .about_banner-image img {
    max-width: 75%;
    height: auto;
    border-radius: 10px;
  }

  .words_container {
    position: relative;
    background-color: #f0f7ff;
    padding: 60px 20px;
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
  }
  
  .words_content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    z-index: 1;
  }
  
  .words_heading {
    font-size: 36px;
    color: #2e0c6e;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .words_text {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0 20px;
  }
  
  .words_flower {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .words_flower img {
    width: 180px;
    height: auto;
    opacity: 0.8;
  }
  
  .words_flower-left {
    left: 0;
  }

  .words_flower-left img {
    width: 200px; /* Increase width to make it larger */
    height: 350px; /* Set a taller height */
    opacity: 0.8;
    position: relative;
    top: 25px; /* Adjust this value as needed to control the overflow */
  }
  
  
  .words_flower-right {
    right: 0;
  }
  
  @media (max-width: 768px) {
    .words_container {
      padding: 40px 15px;
    }
  
    .words_heading {
      font-size: 28px;
    }
  
    .words_text {
      font-size: 16px;
      padding: 0 15px;
    }
  
    .words_flower img {
      width: 120px;
    }
  }
  
  @media (max-width: 480px) {
    .words_flower img {
      width: 80px;
    }
  }
