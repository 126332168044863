.mission_container {
    font-family: Poppins, sans-serif;
    max-width: 1200px;
    margin: 0 60px;
    padding: 20px 20px;
    
  }
  .mission_main_heading{
    font-family: Poppins, sans-serif;
    text-align: center;
    font-size: 42px;
    margin-top: 70px;
  }

  .mission_row {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .mission_box {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }

  .mission_box:hover {
    transform: translateY(-5px);
  }

  .mission_icon {
    width: 80px;
    height: 80px;
    background: #0095FF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .mission_icon svg {
    width: 30px;
    height: 30px;
    color: white;
  }

  .mission_content {
    flex: 1;
  }

  .mission_heading {
    font-size: 22px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0 0 5px 0;
  }

  .mission_text {
    font-size: 17px;
    color: #666666;
    margin: 0;
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    .mission_row {
      flex-direction: column;
    }

    .mission_box {
      width: 100%;
    }
  }