.faq_container {
    width: 100%;
    margin: 0 ;
    padding: 0rem 5rem 0rem 10rem;
    display: flex;
    min-height: 100vh;
    align-items: center;
    background: linear-gradient(135deg, #f0f8ff 0%, #e6f3ff 100%);
  }
  
  .faq_content {
    flex: 1;
  }
  
  .faq_title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
  }
  
  .faq_underline {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 60%;
    height: 4px;
    background-color: #ffd700;
  }
  
  .faq_list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .faq_item {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .faq_question {
    width: 100%;
    padding: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;
    text-align: left;
    font-size: 1.1rem;
    color: #333;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
  .faq_question:hover {
    background-color: #f8f9fa;
  }
  
  .faq_question.faq_active {
    background-color: #4169e1;
    color: white;
  }
  
  .faq_answer {
    padding: 1.2rem;
    color: #666;
    line-height: 1.6;
    background-color: white;
  }
  
  .faq_icon {
    font-size: 0.8rem;
    transition: transform 0.3s;
  }
  
  .faq_active .faq_icon {
    transform: rotate(180deg);
  }
  
  .faq_illustration {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .faq_character {
    width: 300px;
    height: 300px;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%234169e1"><circle cx="12" cy="12" r="11" fill="%234169e1" opacity="0.2"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" fill="%234169e1"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  
  @media (max-width: 768px) {
    .faq_container {
      flex-direction: column;
      padding: 1rem;
    }
  
    .faq_illustration {
      display: none;
    }
  
    .faq_title {
      font-size: 2rem;
    }
}