.contact_container {
    display: flex;
    min-height: 100vh;
    padding: 1.4rem 2rem;
    gap:50px;
    background: linear-gradient(135deg, #f0f8ff 0%, #e6f3ff 100%);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  }
  
  .contact_form {
    flex: 1;
    padding: 2rem;
  }
  
  .contact_form-group {
    margin-bottom: 1rem;
   
  }
  
  .contact_form-row {
    display: flex;
    gap: 1rem;
  }
  
  .contact_form-row .contact_form-group {
    flex: 1;
  }
  
  label {
    display: block;
    margin-bottom: 0.6rem;
    color: black;
    font-size: 1.4rem;
  }
  
  input, textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    background: white;
    font-size: 1rem;
    height:60px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  textarea {
    min-height: 150px;
    resize: vertical;
  }
  
  .contact_submit-button {
    background: #7c3aed;
    color: white;
    border: none;
    border-radius: 15px;
    padding: 0.75rem 2.3rem;
    font-size: 1.4rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact_submit-button:hover {
    background: #6d28d9;
  }
  
  .contact_info {
    flex: 1;
    padding: 0.6rem 2rem;
    position: relative;
  }
  
  .contact_info h1 {
    font-size: 3.5rem;
    color: #333;
    margin-bottom: 1rem;
    line-height: 1.2;
  }
  
  .contact_info p {
    color: #666;
    font-size: 20px;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .contact_details {
    margin-top: 2rem;
  }
  
  .contact_item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .contact_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #3b82f6;
    margin-right: 1rem;
    position: relative;
  }
  
  .contact_phone-icon::before,
  .contact_email-icon::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-repeat: no-repeat;
    filter: brightness(0) invert(1);
  }
  
  .contact_phone-icon::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z'/%3E%3C/svg%3E");
  }
  
  .contact_email-icon::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'/%3E%3C/svg%3E");
  }
  
  .contact_plus-pattern {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    width: 200px;
    height: 200px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.5rem;
    opacity: 0.5;
  }
  
  .contact_plus-pattern::before {
    content: '+';
    color: #ff69b4;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .contact_container {
      flex-direction: column;
    }
    
    .contact_form-row {
      flex-direction: column;
    }
    
    .contact_info h1 {
      font-size: 2rem;
    }
  }
  