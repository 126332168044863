.features {
    /* padding: 40px 20px; */
    text-align: center;
    width: 100%;
    margin-bottom: 175px;
  }
  
  .featuresTitle {
    font-size: 44px;
    color: #333;
    margin-bottom: -20px;
  }
  
  .featuresSubtitle {
    font-size: 15px;
    color: #666;
    margin-bottom: 40px;
  }
  
  .featuresGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 25px;
    width: 100%;
    /* margin-left: 7px; */
    background-color: #e0f3ff;
    /* border-radius: 30px; */
    height: 47vh;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .featuresGrid {
      flex-direction: column;
      align-items: center;
    }
  }
  
